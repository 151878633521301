@use "../../../../styles/variables.module" as *;

.container {
  text-align: center;
  font-size: 1.5rem;
  p {
    padding-top: 2%;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      width: 40%;
      padding: .5rem .5rem 1rem .5rem;
      border: 2px solid $admin-blue-dark;
      background: white;
      display: inline-block;
      margin: 20px;

      @media(max-width: $break-point-sm) {
        margin: 5px;
      }

      &:hover {
        background: $admin-blue-dark;
        color: white;
      }

      button, a {
        width: 100%;

        span:first-of-type {
          display: block;
          font-size: 5rem;
        }
        span:last-of-type {
          font-weight: 700;
        }
      }
    }
  }
}
.headerText {
  text-align: center;
  cursor: default;
  color: $admin-blue-dark;


  h2 {
    padding: 10px 0;
    font-weight: 700;
  }

}
.urlEditor {
  input {
    width: 100%;
    margin-top: 10px;
  }
}